import { useEffect, useState } from "react";

const prefix = "bacod-texteditor-";

const storeToLocalstorage = (key, initialValue) => {
  const prefixedKey = prefix + key;

  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(prefixedKey);
    console.log("INI ===>", typeof jsonValue, jsonValue);
    if (typeof jsonValue !== "undefined") {
      if (jsonValue) return String(jsonValue);
    }

    if (typeof initialValue === "function") {
      return initialValue();
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(prefixedKey, String(value));
  }, [prefixedKey, value]);

  return [value, setValue];
};

export default storeToLocalstorage;
