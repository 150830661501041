/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const Navbar = (props) => {
  return (
    <nav className="main-navbar">
      {props.children}
    </nav>
  );
};

export default Navbar;
