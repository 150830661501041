/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import propTypes from "prop-types";

const Body = (props) => {
  const className = ["main-body"];
  className.push(props.className);
  if (props.navbar) className.push("main-body-with-navbar");
  return (
    <div className={className.join(" ")}>
      {props.children}
    </div>
  );
};

Body.propTypes = {
  navbar: propTypes.bool,
};

export default Body;
