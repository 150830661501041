const userState = {
  isLogin: false,
  userToken: "",
  userData: {},
};

const userReducer = (state = userState, action) => {
  const { type, payload } = action;
  switch (type) {
  case "loginFlag":
    return {
      ...state,
      isLogin: payload,
    };
  case "userToken":
    return {
      ...state,
      userToken: payload,
    };
  case "userData":
    return {
      ...state,
      userData: payload,
    };
  default:
    return {
      ...state,
    };
  }
};

export default userReducer;
