import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth } from "components/atoms";

// #region - IMPORT PAGES
import Home from "pages/home";
import Login from "pages/login";
import Texteditor from "pages/texteditor";
import User from "pages/user";
import Bootcamps from "pages/bootcamps";
import ClassList from "pages/class/list";
import ClassDetail from "pages/class/detail";
import Error404 from "pages/errors/404";
import CodeList from "pages/code";
import Teams from "pages/teams";
import Contoh from "pages/contoh";
import Candidates from "pages/candidates/list";
import CandidateDetail from "pages/candidates/detail";
import Banned from "pages/banned";
import LiveCode from "pages/liveCode";
// #endregion - IMPORT PAGES

const RouteList = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/banned" element={<Banned />} />

        <Route element={<Auth />}>
          <Route path="/bootcamps" element={<Bootcamps />} />
          <Route path="/email" element={<Bootcamps />} />
          <Route path="/task" element={<Bootcamps />} />
          <Route path="/forms" element={<Bootcamps />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="/candidate/:id" element={<CandidateDetail />} />
          <Route path="/code/:id" element={<Texteditor />} />
          <Route path="/class-list/:bootcampId" element={<ClassList />} />
          <Route path="/class/:id" element={<ClassDetail />} />
          <Route path="/room/:bootcampId" element={<Bootcamps />} />
          <Route path="/u/:id" element={<User />} />
          <Route path="/live-code" element={<LiveCode />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouteList;
