import { combineReducers } from "redux";
import userReducer from "./userReducer.js";
import templateReducer from "./templateReducer.js";
import attendanceReducer from "./attendanceReducer.js";

export default combineReducers({
  userReducer,
  templateReducer,
  attendanceReducer,
});
