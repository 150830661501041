
import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container, FormGroup, LogoFacebook, LogoGoogle, LogoGithub, Box } from "components/atoms";
import { storeData, logout, userData } from "utils/helpers.js";
import defaultAvatar from "assets/images/avatar.png";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className="color-white">My Profile</h1>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7">
        <div
          className="profile-cover"
          style={{ backgroundImage: `url('${userData("cover") ? userData("cover") : ""}')` }}
        >
          <button type="button" className="btn-edit-circle"> <i className="feather icon-edit-2"></i> </button>
        </div>
        <div className="profile-avatar">
          <div className="profile-avatar-container">
            <div className="profile-avatar-tools">
              <button className="btn-profile-avatar-tool" title="Change Avatar">
                <i className="feather icon-edit-2"></i>
              </button>
              <button className="btn-profile-avatar-tool bg-red-1 color-white" title="Delete Avatar">
                <i className="feather icon-trash-2"></i>
              </button>
            </div>
            <img
              src={userData("avatar") ? userData("avatar") : defaultAvatar}
              alt="AVATAR USER"
              className="profile-avatar-image"
            />
          </div>
        </div>
        <div className="profile-detail">
          <div className="profile-name">
            {userData("name")}
            {
              userData("isMember") === true &&
              <span className="circle-check" title="MEMBER BACOD">
                <i className="feather icon-check"></i>
              </span>
            }
          </div>
          <div className="profile-title"> LEVEL USER : { userData("role") ? String(userData("role")).toUpperCase() : "user" }</div>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2>About</h2>
        <p> { userData("about") ? parse(userData("about")) : "<i>EMPTY</i>" } </p>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2>Social Media</h2>
        <ul className="profile-social-media d-flex mt-4 cursor-pointer">
          {
            userData("socialMedia") &&
            userData("socialMedia").length > 0 &&
            userData("socialMedia").map((item, index) => {
              return (
                <li
                  key={index}
                  className="mb-3"
                  onClick={() => {
                    window.open(item.link);
                  }}>
                  <Box radius="lg" className="bg-black-1 mr-2 overflow-hidden border-1 bc-black-5 fs-md px-6 py-2">
                    { item.social }
                  </Box>
                </li>
              );
            })
          }
          {/* // <li key={index}>
            //   <div className="social-media">
            //     <div className="social-media-icon"></div>
            //     <div className="social-media-name">{ item.social }</div>
            //   </div>
            //   <div className="social-media-tools">
            //     <button
            //       type="button"
            //       className="btn-social-media-open"
            //       onClick={() => {
            //         window.open(item.link);
            //       }}
            //     >
            //       <i className="feather icon-external-link"></i>
            //     </button>
            //     <button type="button" className="btn-social-media-open">
            //       <i className="feather icon-link"></i>
            //     </button>
            //   </div>
            // </li> */}
          {/* <li>
            Linkedin
          </li>
          <li>
            Youtube
          </li>
          <li>
            Quora
          </li>
          <li>
            Twitter
          </li>
          <li>
            Github
          </li> */}
        </ul>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2>Interests</h2>
        <ul className="d-flex mt-3">
          {
            userData("interests") &&
            userData("interests").length > 0 &&
            userData("interests").map((item, index) => {
              return (
                <li key={index}>
                  <Box radius="lg" className="bg-black-1 mr-2 overflow-hidden border-1 bc-black-5 fs-md px-6 py-2">
                    {item.title}
                  </Box>
                </li>
              );
            })
          }
        </ul>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2>Auth Integrate</h2>
        <div className="mt-6">
          <button type="button" className="btn btn-bacod btn-outline width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important mb-2" title="BELUM JADI WOY">
            <LogoGoogle width="29.28" height="30.72" /> <span className="ml-3">Connected with Google</span>
          </button>
          <button type="button" className="btn btn-black-1 width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important mb-2" title="BELUM JADI WOY">
            <LogoFacebook width="30.72" height="30.72" /> <span className="ml-3">Connect with Facebook</span>
          </button>
          <button type="button" className="btn btn-black-1 width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important" title="BELUM JADI WOY">
            <LogoGithub width="29.76" height="30.72" /> <span className="ml-3">Connect with Github</span>
          </button>
        </div>
      </Box>
    </>
  );
};

export default Profile;
