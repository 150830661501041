/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

// #region - IMPORT MODULES
import React, { useEffect, useState, useRef } from "react";
import { Layout } from "components/templates";
import { Body, LogoCss, LogoHtml, LogoJs } from "components/atoms";
import { TexteditorWindow } from "components/molecules";
import CodeMirror from "react-codemirror";
import localStorage from "hooks/localStorage.js";
import { Link, useNavigate } from "react-router-dom";
import { isLogin, userData } from "utils/helpers.js";
// require("codemirror/lib/codemirror.css");
// require("codemirror/lib/codemirror.js");
// require("codemirror/theme/ayu-mirage.css");
// require("codemirror/mode/javascript/javascript.js");
// require("codemirror/mode/css/css.js");
// require("codemirror/mode/xml/xml.js");
// require("codemirror/mode/htmlmixed/htmlmixed.js");
// require("codemirror/addon/hint/css-hint.js");
// require("codemirror/addon/hint/anyword-hint.js");
// require("codemirror/addon/hint/html-hint.js");
// require("codemirror/addon/hint/javascript-hint.js");
// require("codemirror/addon/hint/xml-hint.js");
// require("codemirror/addon/hint/show-hint.js");
// require("codemirror/addon/hint/show-hint.css");
// require("codemirror/addon/edit/closebrackets.js");
// require("codemirror/addon/edit/closetag.js");
// require("codemirror/addon/edit/matchtags.js");
// require("codemirror/addon/edit/matchbrackets.js");
// require("codemirror/addon/lint/css-lint.js");
// require("codemirror/addon/lint/javascript-lint.js");
// require("codemirror/addon/lint/json-lint.js");
// require("codemirror/addon/lint/lint.js");
// require("codemirror/addon/lint/lint.css");
// require("codemirror/keymap/sublime.js");
// require("codemirror/addon/lint/html-lint.js");
// #endregion - IMPORT MODULES

const Texteditor = () => {
  const [selectedLanguages, setSelectedLanguages] = useState(["html"]);
  const [openMenuLanuages, setOpenMenuLanuages] = useState(false);
  const [htmlCode, setHtmlCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = localStorage("js", "");
  const refBrowser = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      liveCode(htmlCode);
    }, 280);

    return () => clearTimeout(timeout);
  }, [htmlCode, cssCode, jsCode]);

  const updateCode = (language, value) => {
    if (language === "html") {
      setHtmlCode(value);
      localStorage.setItem("texteditorHTML", String(value));
    } else if (language === "js") {
      setJsCode(value);
      localStorage.setItem("texteditorJS", String(value));
    } else if (language === "css") {
      setCssCode(value);
      localStorage.setItem("texteditorCSS", String(value));
    }
  };

  const options = {
    lineWrapping: true,
    lint: true,
    theme: "ayu-mirage",
    lineNumbers: true,
    keyMap: "sublime",
    autoCloseBrackets: true,
    matchBrackets: true,
    extraKeys: {
      "Ctrl-Space": "autocomplete",
      "Alt-Up": "swapLineUp",
      "Alt-Down": "swapLineDown",
      "Shift-Cmd-Alt-Up": "addCursorToPrevLine",
      "Shift-Cmd-Alt-Down": "addCursorToNextLine",
      "Cmd-K Cmd-0": "foldAll",
      "Shift-Alt-Up": "duplicateLine",
      "Alt-Z": "wrapLines",
    },
  };

  const liveCode = (code) => {
    const previewCode = refBrowser.current;
    const previewDocument = previewCode.contentWindow.document;

    previewDocument.open();
    previewDocument.write(code);
    previewDocument.close();
  };

  const showLangugaes = () => {
    setOpenMenuLanuages(!openMenuLanuages);
  };

  const isAvailable = (language) => {
    return selectedLanguages.includes(language);
  };

  const addLanguage = (language) => {
    setSelectedLanguages(selectedLanguages => [...selectedLanguages, language]);
  };

  return (
    <Layout>
      <div className="texteditor-sidebar">
        <div className="texteditor-sidebar-wrapper">
          <div className="texteditor-sidebar-languages">
            {
              selectedLanguages.includes("html") &&
              <button type="button" className="btn-texteditor-sidebar-language">
                <LogoHtml width="25" height="29" />
              </button>
            }
            {
              selectedLanguages.includes("css") &&
              <button type="button" className="btn-texteditor-sidebar-language">
                <LogoCss width="25" height="29" />
              </button>
            }
            {
              selectedLanguages.includes("js") &&
              <button type="button" className="btn-texteditor-sidebar-language">
                <LogoJs width="25" height="25" />
              </button>
            }

            <button
              type="button"
              className={"btn-texteditor-sidebar-language" + (openMenuLanuages ? " active" : "")}
              onClick={showLangugaes}
            >
              <i className="feather icon-plus"></i>
              <div className="texteditor-dropdown-languages">
                <ul className="list-languages">
                  <li className="language">
                    <button type="button" className="btn-language" onClick={() => {
                      addLanguage("html");
                    }}
                    disabled={isAvailable("html")}>
                      HTML
                    </button>
                  </li>
                  <li className="language">
                    <button type="button" className="btn-language" onClick={() => {
                      addLanguage("css");
                    }}
                    disabled={isAvailable("css")}>
                      CSS
                    </button>
                  </li>
                  <li className="language">
                    <button type="button" className="btn-language" onClick={() => {
                      addLanguage("js");
                    }}
                    disabled={isAvailable("js")}>
                      JAVASCRIPT
                    </button>
                  </li>
                  {/* <li className="language">
                    <button type="button" className="btn-language" setSelectedLanguages>
                      MARKDOWN
                    </button>
                  </li> */}
                  {/* <li className="language">
                    <button type="button" className="btn-language" setSelectedLanguages>
                      MYSQL
                    </button>
                  </li> */}
                  {/* <li className="language">
                    <button type="button" className="btn-language" setSelectedLanguages>
                      PHP
                    </button>
                  </li> */}
                </ul>
              </div>
            </button>
          </div>
          {
            isLogin() &&
            <div className="texteditor-sidebar-settings">
              <button type="button" className="btn-texteditor-sidebar-setting" onClick={() => {
                navigate("/code");
              }}>
                <i className="feather icon-save"></i>
              </button>
              <button type="button" className="btn-texteditor-sidebar-setting" onClick={() => {
                navigate("/code");
              }}>
                <i className="feather icon-arrow-left"></i>
              </button>
              <button type="button" className="btn-texteditor-sidebar-setting">
                <i className="feather icon-share-2"></i>
              </button>
              <button type="button" className="btn-texteditor-sidebar-setting">
                <i className="feather icon-settings"></i>
              </button>
            </div>
          }
        </div>
      </div>
      <Body className="texteditor-body">
        <div className="texteditor-main">
          <TexteditorWindow
            title="TEXT EDITOR - HTML"
          >
            <CodeMirror
              value={htmlCode}
              onChange={(e) => { updateCode("html", e); }}
              options={{ ...options, mode: "htmlmixed" }}
              className="bacod-texteditor"
            />
          </TexteditorWindow>
          {/* <TexteditorWindow title="TEXT EDITOR - CSS">
            <CodeMirror value={htmlCode} onChange={(e) => { updateCode(e.target.value); }} options={{ ...options, mode: "htmlmixed" }} />
          </TexteditorWindow> */}
          {/* <TexteditorWindow title="BROWSER OUTPUT">
            <CodeMirror value={code} onChange={(e) => { updateCode(e.target.value); }} options={options} />
          </TexteditorWindow>
          <TexteditorWindow title="BROWSER OUTPUT">
            <CodeMirror value={code} onChange={(e) => { updateCode(e.target.value); }} options={options} />
          </TexteditorWindow> */}
        </div>
        <div className="texteditor-browser">
          <TexteditorWindow title="BROWSER OUTPUT">
            <iframe
              ref={refBrowser}
              title="output"
              frameBorder="0"
              sandbox="allow-scripts allow-same-origin"
              className="texteditor-output"
            >
            </iframe>
          </TexteditorWindow>
        </div>
      </Body>
    </Layout>
  );
};

export default Texteditor;
