/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container, FormGroup, LogoFacebook, LogoGoogle, LogoGithub, Box } from "components/atoms";
import { storeData, logout, userData } from "utils/helpers.js";
import { Link, useNavigate } from "react-router-dom";
import { setLogin, setToken, setUserData } from "store/actions/userAction.js";
import { useDispatch } from "react-redux";
import { Modal } from "components/organisms";
import axios from "services/axios";
import bacod from "assets/images/bacod-sm.png";
import Profile from "./menu/Profile";
import Badges from "./menu/Badges";
import Certificates from "./menu/Certificates";
import Settings from "./menu/Settings";
import "./index.scss";

const MainProfile = () => {
  const [isModalLogout, setIsModalLogout] = useState(false);
  const [profileTab, setProfileTab] = useState("profile");
  const navigate = useNavigate();

  const closeModalLogout = () => {
    setIsModalLogout(false);
  };

  const openModalLogout = () => {
    setIsModalLogout(true);
  };

  const logoutAction = () => {
    logout(() => {
      navigate("/login");
    });
  };

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            <Link to="/">
              <img src={bacod} alt="BACOD Logo" className="login-logo" />
            </Link>
          </div>
          <div className="navbar-program">
            <button type="button" className="hidden-anchor color-white bold" onClick={openModalLogout}>
              LOGOUT
            </button>
          </div>
        </div>
      </Navbar>
      <Body className="pt-15 pb-15">
        <Container className="pt-15">
          <div className="row">
            <div className="col-4">
              <Box fullWidth radius="sm" shadow className="bg-black-4 px-3 py-3 border-1 bc-black-7 profile-side-menu">
                <ul className="profile-menu">
                  <li className={profileTab === "profile" && "active"}>
                    <button
                      className="btn-profile-menu"
                      onClick={() => { setProfileTab("profile"); }}
                    >
                      <i className="feather icon-user mr-3"></i> My Profile
                    </button>
                  </li>
                  <li className={profileTab === "certificates" && "active"}>
                    <button
                      className="btn-profile-menu"
                      onClick={() => { setProfileTab("certificates"); }}
                    >
                      <i className="feather icon-file mr-3"></i> Certificates
                    </button>
                  </li>
                  <li className={profileTab === "badges" && "active"}>
                    <button
                      className="btn-profile-menu"
                      onClick={() => { setProfileTab("badges"); }}
                    >
                      <i className="feather icon-award mr-3"></i> Badges
                    </button>
                  </li>
                  <li className={profileTab === "settings" && "active"}>
                    <button
                      className="btn-profile-menu"
                      onClick={() => { setProfileTab("settings"); }}
                    >
                      <i className="feather icon-settings mr-3"></i> Settings
                    </button>
                  </li>
                </ul>
              </Box>
            </div>
            <div className="col-8">
              {
                (() => {
                  switch (profileTab) {
                  case "profile":
                    return <Profile />;
                  case "badges":
                    return <Badges />;
                  case "certificates":
                    return <Certificates />;
                  case "settings":
                    return <Settings />;
                  default:
                    break;
                  }
                })()
              }
            </div>
          </div>
        </Container>
      </Body>
      <Modal show={isModalLogout} size="lg" radius="md" onClose={closeModalLogout} className="bg-black-1">
        <div className="px-6 pb-5 pt-5">
          <div
            className="display-flex align-x-center align-y-center mb-2"
            style={{ fontSize: "7rem" }}
          >
            <i className="feather icon-help-circle color-white"></i>
          </div>
          <h2 className="text-center color-white">ARE YOU SURE WANT TO LOGOUT?</h2>
          <div className="text-center">
            <button
              className="btn btn-white btn-rounded px-8 mt-10 mr-5"
              onClick={closeModalLogout}
            >
              NO
            </button>
            <button
              className="btn btn-primary btn-rounded px-8 mt-10"
              onClick={logoutAction}
            >
              YES
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default MainProfile;
