/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import defaultAvatar from "assets/images/avatar.png";

const ClassMentorItem = (props) => {
  return (
    <div className="class-item-mentor-item">
      <img src={props.avatar ? props.avatar : defaultAvatar} className="class-item-mentor-avatar-image"/>
      <div className="class-item-mentor-name">{ props.name }</div>
    </div>
  );
};

export default ClassMentorItem;
