/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import propTypes from "prop-types";

const SkeletonLoading = (props) => {
  const className = ["skeleton-loading"];
  const skeletonStyle = {};

  className.push(props.className);
  if (props.width) skeletonStyle.width = props.width;
  if (props.height) skeletonStyle.height = props.height;
  if (props.radius) skeletonStyle.borderRadius = props.radius;

  return (
    <div className={className.join(" ")} style={skeletonStyle}></div>
  );
};

export default SkeletonLoading;
