/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React, { useState } from "react";
import { MenuSidebarItem } from "components/atoms";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { closeSidebarMenu } from "store/actions/templateAction.js";

const MenuSidebar = ({ toggleSidebarMenu }) => {
  const isSidebarMenuOpen = useSelector(state => state.templateReducer.isSidebarMenuOpen);
  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(closeSidebarMenu());
  };

  return (
    <div className={"menu-sidebar" + (isSidebarMenuOpen ? " active" : "")}>
      <div className="menu-sidebar-wrapper">
        <div className="menu-sidebar-header">
          <div className="menu-sidebar-title"> MAIN MENU </div>
          <button type="button" className="menu-sidebar-close" onClick={() => { closeSidebar(); }}>
            <i className="feather icon-x"></i>
          </button>
        </div>
        <div className="menu-sidebar-body">
          <ul className="menu-sidebar-list">\
            <MenuSidebarItem title="BOOTCAMPS" link="/bootcamps">
              <i className="feather icon-layers"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="CANDIDATES" link="/candidates">
              <i className="feather icon-users"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="INTERVIEW" link="/interview">
              <i className="feather icon-calendar"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="FORMS" link="/forms">
              <i className="feather icon-file-text"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="EMAIL" link="/email">
              <i className="feather icon-mail"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="TASK" link="/task">
              <i className="feather icon-clipboard"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="USERS" link="/users">
              <i className="feather icon-user"></i>
            </MenuSidebarItem>
            <MenuSidebarItem title="CERTIFICATES" link="/certificates">
              <i className="feather icon-award"></i>
            </MenuSidebarItem>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuSidebar;
