import React from "react";
import { Box } from "components/atoms";
import "./index.scss";

const CertificateListItem = () => {
  return (
    <Box fullWidth radius="sm" className="bg-black-7 mt-5 overflow-hidden">
      <div className="certificate-item-header">

      </div>
      <div className="certificate-item-body">
      </div>
    </Box>
  );
};

export default CertificateListItem;
