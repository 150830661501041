import React, { useEffect, useState } from "react";
import { FormGroup } from "components/atoms";
import { useSelector, useDispatch } from "react-redux";
import { setAttendance } from "store/actions/attendanceAction.js";
import { attendanceUnlock } from "utils/api";
import axios from "services/axios";

const AttendanceUnlock = (props) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const dataConference = useSelector(state => state.attendanceReducer.dataConference);
  const dispatch = useDispatch();

  const closeAttendance = () => {
    if (props.closeAttendance) props.closeAttendance();
  };

  const unlockAttendance = (e) => {
    e.preventDefault();
    const conferenceId = dataConference.id;
    setLoading(true);
    attendanceUnlock(conferenceId, code)
      .then((response) => {
        if (response.data.success) {
          dispatch(setAttendance(true));
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          dispatch(setAttendance(false));
          setMessage(error.response.data.message);
          console.log("ERROR =====>", error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={unlockAttendance}>
      <div
        className="display-flex align-x-center align-y-center mb-2"
        style={{ fontSize: "6rem" }}
      >
        <i className="feather icon-lock color-red-1"></i>
      </div>
      <h1 className="text-center color-red-1">ATTENDANCE PASSWORD</h1>
      <FormGroup className="mb-4 mt-8" message={message} messageAlign="center">
        <input
          type="text"
          onChange={(e) => {
            setCode(e.target.value);
          }}
          className="form-input text-center form-input-rounded bg-black-3 px-7 color-white"
          placeholder="PASSWORD HERE"
          maxLength="13"
          disabled={loading}
        />
      </FormGroup>
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-red-1 bold btn-rounded px-8 py-2 mt-2 mr-5"
          onClick={closeAttendance}
          disabled={loading}
        >
          { loading ? "LOADING..." : "UNLOCK" }
        </button>
      </div>
    </form>
  );
};

export default AttendanceUnlock;
