/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useState } from "react";
import { Layout } from "components/templates";
import { Body, Container } from "components/atoms";
import bacod from "assets/images/bacod-sm.png";
import { logout } from "utils/helpers";

const BannedPage = () => {
  return (
    <Layout>
      <Body>
        <Container>
          <div className="pt-15 d-flex align-x-center align-y-center flex-direction-column">
            <img src={bacod} alt="BACOD Logo" className="banned-logo" />
            <h1 className="text-center color-red-1 mt-8">YOUR ACCOUNT HAS BEEN BANNED</h1>
            <p className="text-center">Maybe you broke the rules at the BACOD event, try contacting a BACOD member to find out more</p>
          </div>
          <div className="text-center mt-10 mb-10">
            <button
              type="button"
              className="btn btn-red-1 btn-rounded px-7 py-2"
              onClick={() => logout(() => { window.location.href = "/"; })}
            >
              CHANGE ACCOUNT
            </button>
          </div>
        </Container>
      </Body>
    </Layout>
  );
};

export default BannedPage;
