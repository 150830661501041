export const openSidebarMenu = () => {
  return ({
    type: "OPEN_SIDEBAR_MENU",
  });
};

export const closeSidebarMenu = () => {
  return ({
    type: "CLOSE_SIDEBAR_MENU",
  });
};

export const setSidebarMenu = (isOpen) => {
  return ({
    type: "SET_SIDEBAR_MENU",
    payload: isOpen,
  });
};
