/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "assets/images/avatar.png";

const Profile = (props) => {
  const navigate = useNavigate();
  const [dropdownActive, setDropdownActive] = useState(false);
  const avatar = props.avatar ? props.avatar : "";

  const openDropdown = () => {
    setDropdownActive(true);
  };

  const openProfile = () => {
    navigate(`/u/${props.id}`);
  };

  return (
    <div className="profile-navbar">
      <div className="profile-navbar-wrapper">
        <div className="profile-navbar-identity" onClick={openProfile}>
          <div className="profile-navbar-name">{props.name}</div>
          <div className="profile-navbar-avatar">
            <img src={avatar || defaultAvatar} alt="USER AVATAR" />
          </div>
        </div>
        {/* <div className="profile-navbar-dropdown">
          <ul className="profile-navbar-menu-list">
            <li>
              <a href="#" className="profile-navbar-menu">
                MY PROFILE
              </a>
            </li>
            <li>
              <a href="#" className="profile-navbar-menu">
                SETTINGS
              </a>
            </li>
            <li>
              <a href="#" className="profile-navbar-menu">
                LOGOUT
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
