import React from "react";
import propTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

const Auth = ({ redirectPath }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

Auth.propTypes = {
  redirectPath: propTypes.string,
};

Auth.defaultProps = {
  redirectPath: "/login",
};

export default Auth;
