/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const FormGroup = (props) => {
  const className = ["form-group"];
  const messageClass = ["form-input-message form-input-message-error mt-3"];
  className.push(props.className);

  if (props.messageAlign) {
    if (props.messageAlign === "left") {
      messageClass.push("text-left");
    } else if (props.messageAlign === "center") {
      messageClass.push("text-center");
    } else if (props.messageAlign === "right") {
      messageClass.push("text-right");
    }
  }

  return (
    <div className={className.join(" ")}>
      <div className="form-group-wrapper">
        {props.children}
      </div>
      {
        props.message &&
        <div className={messageClass.join(" ")}>
          {props.message}
        </div>
      }
    </div>
  );
};

export default FormGroup;
