import React from "react";
import propTypes from "prop-types";

const FormInput = (props) => {
  const {
    value,
    type,
    placeholder,
    name,
    required,
  } = props;
  const className = ["form-input"];
  if (props.shadow) className.push("form-input-shadow");
  className.push(props.className);

  const onChange = (event) => {
    const target = {
      target: {
        name: name,
        value: event.target.value,
      },
    };
    if (type === "tel") {
      if (event.target.validity.valid) props.onChange(target);
    } else {
      props.onChange(target);
    }
  };

  return (
    <input
      name={name}
      type={type}
      className={className.join(" ")}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      required={required}
    />
  );
};

FormInput.defaultProps = {
  type: "text",
  pattern: "",
  placeholder: "Please type here...",
};

FormInput.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  onChange: propTypes.func.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
  className: propTypes.string,
  required: propTypes.bool,
};

export default FormInput;
