/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import { useEffect, React } from "react";
import { Layout, Navbar, MenuSidebar } from "components/templates";
import { Body, Flex, FormGroup } from "components/atoms";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createPlayground } from "livecodes";
import { Profile, Modal } from "components/organisms";
import { isLogin, userData } from "utils/helpers.js";
import bacod from "assets/images/bacod-sm.png";
import "./index.scss";

const LiveCode = () => {
  const navigate = useNavigate();
  useEffect(() => {
    createPlayground("#live-code").then(async (playground) => {
      await playground.run();
    });
  }, 1000);

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            <Link to="/">
              <img src={bacod} alt="BACOD Logo" className="login-logo" />
            </Link>
          </div>
          <div className="navbar-program">
            <Profile avatar={userData("avatar")} name={userData("name")} id={userData("id")} />
          </div>
        </div>
      </Navbar>
      <Body>
        <div id="live-code"></div>
      </Body>
    </Layout>
  );
};

export default LiveCode;
