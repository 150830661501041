/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useEffect, useState, useRef } from "react";
import { Layout, Navbar, MenuSidebar } from "components/templates";
import { Body, Flex, FormGroup } from "components/atoms";
import { Profile, Modal } from "components/organisms";
import { ClassItem } from "components/molecules";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isLogin, userData } from "utils/helpers.js";
import parse from "html-react-parser";
import { conferenceList } from "utils/api";
import { closeSidebarMenu, openSidebarMenu } from "store/actions/templateAction.js";
import bacod from "assets/images/bacod-sm.png";

const MyClass = () => {
  const { bootcampId } = useParams();
  const [showMenu, setShowMenu] = useState(false);
  const [loadingGetClass, setLoadingGetClass] = useState(false);
  const [listClass, setListClass] = useState([]);
  const [listMentor, setListMentor] = useState({});
  const toggleSidebarMenu = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getClassList();
    dispatch(closeSidebarMenu());
  }, []);

  const getClassList = () => {
    setLoadingGetClass(true);
    conferenceList(100, 1, bootcampId)
      .then((response) => {
        if (response.data.success) {
          const dataConference = response.data.results;
          const mentors = {};
          dataConference.data.forEach((item) => {
            mentors[item.id] = [];
            if (item.link && item.link.length > 0) {
              item.link.forEach((item2) => {
                mentors[item.id].push(item2.mentor);
              });
            }
          });
          setListMentor(mentors);
          setListClass(dataConference.data);
        } else {
          setListClass([]);
        }
      })
      .catch((error) => {
        setListClass([]);
        console.log("ERROR =====>", error);
      })
      .finally(() => {
        setLoadingGetClass(false);
      });
  };

  const openMenu = () => {
    dispatch(openSidebarMenu());
  };

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            { isLogin()
              ? <Link to="/">
                <img src={bacod} alt="BACOD Logo" className="login-logo" />
              </Link>
              : <Link to="/what" className="color-white hidden-anchor">WHAT IS BACOD?</Link>
            }
          </div>
          <div className="navbar-program">
            {
              isLogin()
                ? <Profile avatar={userData("avatar")} name={userData("name")} id={userData("id")} />
                : <button type="button" onClick={() => { navigate("/login"); }} className="btn btn-primary btn-rounded bold px-6">LOGIN</button>
            }
          </div>
        </div>
      </Navbar>
      <Body navbar>
        <h1 className="text-center mt-5 underline-center">BOOTCAMP SEASON 3</h1>
        <div className="class-wrapper">
          {
            !loadingGetClass &&
            <ul className="class-list">
              {
                listClass.map((item, index) => {
                  return (
                    <ClassItem
                      title={item.title}
                      mentors={listMentor[item.id]}
                      link={`/class/${item.id}`}
                      key={index}
                    >
                      { item.icon ? parse(item.icon) : <i className="feather icon-monitor"></i>}
                    </ClassItem>
                  );
                })
              }
            </ul>
          }
        </div>
        {/* <Flex className="align-x-center align-y-center pb-10">
          <button className="bg-primary color-black-1 rounded-icon rounded-icon-md fs-lg cursor-pointer bounce">
            <i className="feather icon-chevron-down"></i>
          </button>
        </Flex> */}
      </Body>
    </Layout>
  );
};

export default MyClass;
