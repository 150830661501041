/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const LogoCss = (props) => {
  return (
    <svg width={props.width ? props.width : 36} height={props.height ? props.height : 42} viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L3.27188 37.1062L18 42L32.7281 37.1062L36 0H0ZM29.3531 7.5L28.9031 11.9344L18.0938 16.5563L18.0656 16.5656H28.5188L27.3187 30.3094L18.1125 33L8.85 30.2625L8.25 23.3344H12.8344L13.1344 26.925L18.0656 28.1719L23.1937 26.7281L23.5406 20.9531L7.95 20.9062V20.8969L7.93125 20.9062L7.59375 16.5656L18.1031 12.1875L18.7125 11.9344H7.19062L6.64688 7.5H29.3531Z" fill={props.color ? props.color : "#1B84C1"} />
    </svg>
  );
};

export default LogoCss;
