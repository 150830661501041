import React, { useEffect, useState } from "react";

const AttendanceResults = (props) => {
  const closeAttendance = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <>
      <div
        className="display-flex align-x-center align-y-center mb-2"
        style={{ fontSize: "6rem" }}
      >
        <i className="feather icon-check-square color-primary"></i>
      </div>
      <h1 className="text-center color-primary">ATTENDANCE SUCCESS</h1>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-white bold btn-rounded px-8 py-2 mt-10 mr-5"
          onClick={closeAttendance}
        >
          CLOSE
        </button>
      </div>
    </>
  );
};

export default AttendanceResults;
