import axios from "services/axios";
/*
Note :
Hash Region for grouping, use VSCODE to enable it.
*/

// #region - AUTH

export const authLogin = (user, password) => {
  return axios.post("/auth/login", {
    user: user,
    password: password,
  });
};

// #endregion - AUTH

// #region - ATTENDANCE

export const attendanceUnlock = (conferenceId, code) => {
  return axios.get(`/attendance/unlock/${conferenceId}`, {
    params: { code: code },
  });
};

export const attendanceCreate = (formData) => {
  return axios.post("/attendance", formData);
};

// #endregion - ATTENDANCE

// #region - CONFERENCE

export const conferenceToday = (bootcampId, userId) => {
  return axios.get("/conference/today", {
    params: {
      bootcampId: bootcampId,
      userId: userId,
    },
  });
};

export const conferenceDetail = (conferenceId) => {
  return axios.get(`/conference/${conferenceId}`);
};

export const conferenceList = (limit, page, bootcampId) => {
  return axios.get("/conference", {
    params: {
      page: page,
      limit: limit,
      bootcampId: bootcampId,
    },
  });
};

// #endregion - CONFERENCE

// #region - BACOD TEAMS

export const listMembers = (limit, page, search) => {
  return axios.get("/members", {
    params: {
      limit: limit,
      page: page,
      search: search,
    },
  });
};

export const userCheck = (userId) => {
  return axios.get(`/users/check/${userId}`);
};

// #endregion - BACOD TEAMS
