
import React, { useState } from "react";
import { Box } from "components/atoms";

const Badges = () => {
  return (
    <>
      <h1 className="color-white">Badges</h1>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7">
        <h1 className="text-center mt-15 mb-15">THERE IS NO BADGES</h1>
      </Box>
    </>
  );
};

export default Badges;
