export const setAttendance = (attendanceUnlock) => {
  return ({
    type: "SET_ATTENDANCE",
    payload: attendanceUnlock,
  });
};

export const setSubmitAttendance = (isSubmitAttendance) => {
  return ({
    type: "SET_SUBMIT_ATTENDANCE",
    payload: isSubmitAttendance,
  });
};

export const setDataConference = (dataConference) => {
  return ({
    type: "SET_DATA_CONFERENCE",
    payload: dataConference,
  });
};
