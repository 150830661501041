/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import propTypes from "prop-types";

const Box = (props) => {
  const className = [props.className];
  className.push("box");
  if (props.shadow) className.push("box-shadow");
  if (props.fullWidth) className.push("box-full-width");
  if (props.radius) className.push(`box-radius-${props.radius}`);
  return (
    <div className={className.join(" ")}>
      {props.children}
    </div>
  );
};

Box.propTypes = {
  shadow: propTypes.bool,
  fullWidth: propTypes.bool,
  radius: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export default Box;
