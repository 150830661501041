/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container } from "components/atoms";
import { userData } from "utils/helpers.js";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal, Profile } from "components/organisms";
import axios from "services/axios";
import defaultAvatar from "assets/images/avatar.png";
import bacod from "assets/images/bacod-sm.png";

const Login = () => {
  const [isModalLogout, setIsModalLogout] = useState(false);
  const navigate = useNavigate();

  const projects = ["Project 123143", "HTML Example Project", "CSS BASIC Selector"];

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            <Link to="/">
              <img src={bacod} alt="BACOD Logo" className="login-logo" />
            </Link>
          </div>
          <div className="navbar-program">
            <Profile avatar={userData("avatar")} name={userData("name")} id={userData("id")} />
          </div>
        </div>
      </Navbar>
      <Body className="pt-15">
        <Container className="pt-15">
          <h1 className="text-center">CANDIDATES</h1>
          <div className="list-projects">
            <button type="button" className="btn-project" onClick={() => { navigate("/code/new"); }}>
              NEW PROJECT
            </button>
            <div className="text-center mb-3 fs-md">RECENT PROJECTS</div>
            {
              projects.map((item, index) => {
                return (
                  <button type="button" className="btn-project" key={index}>
                    <div className="project-title">
                      {item}
                    </div>
                  </button>
                );
              })
            }
            {/* <div className="project-tools">
              <button className="btn-project-tools">
                <i className="feather icon-more-vertical"></i>
              </button>
              <ul className="project-tool-list">
                <li>OPEN</li>
                <li>COPY LINK</li>
                <li>DOWNLOAD</li>
                <li>DELETE</li>
              </ul>
            </div> */}
          </div>
        </Container>
      </Body>
    </Layout>
  );
};

export default Login;
