
import React, { useState } from "react";
import { Box } from "components/atoms";
import { CertificateListItem } from "components/molecules";

const Certificates = () => {
  const [listData, setListData] = useState(["TEST A", "TEST B", "TEST C", "TEST D", "TEST E"]);

  return (
    <>
      <h1 className="color-white">Certificates</h1>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7">
        <div className="profile-page-filters">
          <div className="filter-search">

          </div>
          <div className="filter-sort">

          </div>
        </div>
        <div className="px-7">
          <div className="row">
            {
              listData.map((item, index) => {
                return (
                  <div className="col-4" key={index}>
                    <CertificateListItem />
                  </div>
                );
              })
            }
          </div>
        </div>
        <h1 className="text-center mt-15 mb-15"><i>THERE IS NO CERTIFICATES</i></h1>
      </Box>
    </>
  );
};

export default Certificates;
