/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const LogoHtml = (props) => {
  return (
    <svg width={props.width ? props.width : 36} height={props.height ? props.height : 42} viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L3.27188 37.1062L17.9531 42L32.7281 37.1062L36 0H0ZM28.8938 11.9906H11.6625L12.0469 16.6219H28.5094L27.2344 30.5344L18.0563 33.0656V33.0938H17.9531L8.7 30.5344L8.1375 23.4281H12.6094L12.9375 27L17.9531 28.3594L22.9875 27L23.55 21.1687H7.90313L6.70312 7.51875H29.3063L28.8938 11.9906Z" fill={props.color ? props.color : "#E96228"} />
    </svg>
  );
};

export default LogoHtml;
