export const setLogin = (loginFlag) => {
  return ({
    type: "loginFlag",
    payload: loginFlag,
  });
};

export const setToken = (token) => {
  return ({
    type: "userToken",
    payload: token,
  });
};

export const setUserData = (userData) => {
  return ({
    type: "userData",
    payload: userData,
  });
};
