import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container, FormGroup, LogoFacebook, LogoGoogle, LogoGithub } from "components/atoms";

const Bootcamp = () => {
  return (
    <Layout>
      <Body>
        <h1>TESTING</h1>
      </Body>
    </Layout>
  );
};

export default Bootcamp;
