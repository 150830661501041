/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container, FormGroup, LogoFacebook, LogoGoogle, LogoGithub } from "components/atoms";
import { storeData, storeToken } from "utils/helpers.js";
import { Link, useNavigate } from "react-router-dom";
import { setLogin, setToken, setUserData } from "store/actions/userAction.js";
import { useDispatch } from "react-redux";
import { Modal } from "components/organisms";
import bacod from "assets/images/bacod-sm.png";
import { authLogin } from "utils/api";

const Login = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalRegister, setIsModalRegister] = useState(false);
  const [isModalWarning, setIsModalWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const navigate = useNavigate();

  const submitForm = (event) => {
    event.preventDefault();
    setLoading(true);
    authLogin(user, password)
      .then((response) => {
        if (response.data.success) {
          const token = response.data.accessToken;
          const userData = response.data.userData;

          const iframe = document.createElement("iframe");
          iframe.src = "https://sample.resitdc.id";
          iframe.style.display = "none";
          document.body.appendChild(iframe);

          iframe.onload = () => {
            console.log("MASUK SINI");
            iframe.contentWindow?.postMessage({ authToken: token }, "https://sample.resitdc.id");
          };

          // SET TOKEN
          storeToken(token);
          dispatch(setToken(token));
          // SET USERDATA
          storeData(userData);
          // dispatch(setUserData(userData));
          // SET LOGIN FLAG
          dispatch(setLogin(true));
          navigate("/");
        } else {
          alert(response.data.message);
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.response.data) {
            setWarning(error.response.data.message);
          } else {
            alert("OOPS ERROR");
            setWarning("SOMETHING WORNG, PLEASE CONTACT ADMIN");
            console.log("ERROR ===>", error);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const register = () => {
    setIsModalRegister(true);
  };

  const closeRegister = () => {
    setIsModalRegister(false);
  };

  const setWarning = (message) => {
    setWarningMessage(message);
    setIsModalWarning(true);
  };

  const closeWarning = () => {
    setIsModalWarning(false);
    setWarningMessage("");
  };

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            <Link to="/">
              <img src={bacod} alt="BACOD Logo" className="login-logo" />
            </Link>
          </div>
          <div className="navbar-program">
            <button
              type="button"
              className="btn btn-primary btn-rounded bold px-6"
              title="GA ADA REGISTER, INI BIAR UI BAGUS AJA"
              onClick={register}
            >
              REGISTER
            </button>
          </div>
        </div>
      </Navbar>
      <Body>
        <Modal show={isModalRegister} size="xl" radius="md" onClose={closeRegister} className="bg-black-1">
          <div className="px-6 pb-5 pt-5">
            <div
              className="display-flex align-x-center align-y-center mb-2"
              style={{ fontSize: "7rem" }}
            >
              <i className="feather icon-info color-red-1"></i>
            </div>
            <h2 className="text-center color-red-1">SORRY WE DONT HAVE REGISTER FEATURE</h2>
            <p className="text-center color-white">if you want to have an account, you must at least join our events or become an internal member of BACOD</p>
            <div className="text-center">
              <button
                className="btn btn-white btn-rounded px-8 mt-10 mr-5"
                onClick={closeRegister}
              >
              OK THANKS
              </button>
            </div>
          </div>
        </Modal>
        <Modal show={isModalWarning} size="xl" radius="md" onClose={closeRegister} className="bg-black-1">
          <div className="px-6 pb-5 pt-5">
            <div
              className="display-flex align-x-center align-y-center mb-2"
              style={{ fontSize: "7rem" }}
            >
              <i className="feather icon-alert-triangle color-red-1"></i>
            </div>
            <h2 className="text-center color-red-1">OPS..</h2>
            <p className="text-center color-white">{ warningMessage }</p>
            <div className="text-center">
              <button
                className="btn btn-red-1 btn-rounded px-8 mt-10 mr-5"
                onClick={closeWarning}
              >
              CLOSE
              </button>
            </div>
          </div>
        </Modal>
        <Container>
          <div className="login-header mb-15">
            <h1 className="login-title">Login to Your Account</h1>
            <p className="login-headline d-none-mobile px-15">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam obcaecati repudiandae, labore eveniet iure officiis sunt porro culpa temporibus autem perferendis dolores ipsa numquam commodi, architecto dignissimos. Omnis, cum natus!</p>
          </div>
          <div className="login-form-list mb-15">
            <div className="login-form-user">
              <form onSubmit={submitForm} className="width-full">
                <FormGroup className="mb-4">
                  <input
                    type="text"
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                    className="form-input form-input-rounded bg-black-3 px-7 color-white"
                    placeholder="Email / Username / Phone"
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <input
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="form-input form-input-rounded bg-black-3 px-7 color-white"
                    placeholder="Password"
                  />
                </FormGroup>
                <div>
                  <button type="submit" className="btn btn-bacod btn-outline width-full btn-radius-rounded py-5 px-6 absolute-y-center text-left-important" disabled={loading}>
                    {
                      loading
                        ? "LOADING..."
                        : <><span className="mr-2">Login to Your Account</span> <i className="feather icon-arrow-right fs-xl"></i></>
                    }
                  </button>
                </div>
              </form>
            </div>
            <div className="login-form-block">
            </div>
            <div className="login-form-thirdparty">
              <button type="button" className="btn btn-bacod btn-outline width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important mb-3" title="BELUM JADI WOY" disabled={loading}>
                <LogoGoogle width="29.28" height="30.72" /> <span className="ml-3">Login with Google</span>
              </button>
              <button type="button" className="btn btn-bacod btn-outline width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important mb-3" title="BELUM JADI WOY" disabled={loading}>
                <LogoFacebook width="30.72" height="30.72" /> <span className="ml-3">Login with Facebook</span>
              </button>
              <button type="button" className="btn btn-bacod btn-outline width-full btn-radius-rounded py-3 px-4 absolute-y-center text-left-important" title="BELUM JADI WOY" disabled={loading}>
                <LogoGithub width="29.76" height="30.72" /> <span className="ml-3">Login with Github</span>
              </button>
            </div>
          </div>
          <div className="text-center mb-10">
            <Link to="/forgot-password" className="forgot-password" title="TOLOL KENAPA HARUS LUPA, NYUSAHIN DEVELOPER AJA">Forgot Password?</Link>
          </div>
        </Container>
      </Body>
    </Layout>
  );
};

export default Login;
