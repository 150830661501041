/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const TexteditorWindow = (props) => {
  const onClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div className="texteditor-window">
      <div className="texteditor-window-header">
        <div className="texteditor-window-title">{props.title}</div>
        <div className="texteditor-window-tools">
          <button type="button" className="texteditor-window-tool">
            <i className="feather icon-minus"></i>
          </button>
          <button type="button" className="texteditor-window-tool">
            <i className="feather icon-maximize"></i>
          </button>
        </div>
      </div>
      <div className="texteditor-window-main">
        {props.children}
      </div>
    </div>
  );
};

export default TexteditorWindow;
