/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const Container = (props) => {
  const className = ["container"];
  className.push(props.className);
  return (
    <div className={className.join(" ")}>
      {props.children}
    </div>
  );
};

export default Container;
