/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
// import propTypes from "prop-types";

const Resitdc = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 130 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M81 25L43 92.5H27L0 46L27 0H42.5L16 46L34.5 79.5L65.5 25H81Z" fill="#19F8FF"/>
      <path d="M50 14L57.5 0H102.5L129.5 46L102.5 92.5H87.5L72.5 67L80 54L95 79.5L114 46L95 14H50Z" fill="white"/>
    </svg>
  );
};

export default Resitdc;
