/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const LogoJs = (props) => {
  return (
    <svg width={props.width ? props.width : 36} height={props.height ? props.height : 36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.1429 0H3.85714C1.72768 0 0 1.72768 0 3.85714V32.1429C0 34.2723 1.72768 36 3.85714 36H32.1429C34.2723 36 36 34.2723 36 32.1429V3.85714C36 1.72768 34.2723 0 32.1429 0ZM19.5911 28.0768C19.5911 31.5804 17.5339 33.1795 14.5366 33.1795C11.8286 33.1795 10.2616 31.7812 9.45804 30.0857L12.2143 28.4223C12.7446 29.3625 13.2268 30.158 14.392 30.158C15.5009 30.158 16.208 29.7241 16.208 28.0286V16.5295H19.5911V28.0768ZM27.5946 33.1795C24.4527 33.1795 22.4196 31.6848 21.4313 29.7241L24.1875 28.133C24.9107 29.3143 25.8589 30.1902 27.5223 30.1902C28.9205 30.1902 29.8205 29.4911 29.8205 28.5187C29.8205 27.3616 28.9045 26.9518 27.3536 26.2687L26.5098 25.9071C24.067 24.8705 22.4518 23.5607 22.4518 20.8045C22.4518 18.2652 24.3884 16.3366 27.4018 16.3366C29.5554 16.3366 31.0982 17.0839 32.2071 19.0446L29.5714 20.7321C28.9929 19.6955 28.3661 19.2857 27.3937 19.2857C26.4054 19.2857 25.7786 19.9125 25.7786 20.7321C25.7786 21.7446 26.4054 22.1545 27.8598 22.7893L28.7036 23.1509C31.5804 24.3804 33.1955 25.642 33.1955 28.4705C33.1955 31.508 30.8009 33.1795 27.5946 33.1795Z" fill={props.color ? props.color : "#F7D138"} />
    </svg>
  );
};

export default LogoJs;
