const templateState = {
  isSidebarMenuOpen: false,
};

const userReducer = (state = templateState, action) => {
  const { type, payload } = action;
  switch (type) {
  case "OPEN_SIDEBAR_MENU":
    return {
      ...state,
      isSidebarMenuOpen: true,
    };
  case "CLOSE_SIDEBAR_MENU":
    return {
      ...state,
      isSidebarMenuOpen: false,
    };
  case "SET_SIDEBAR_MENU":
    return {
      ...state,
      isSidebarMenuOpen: payload,
    };
  default:
    return {
      ...state,
    };
  }
};

export default userReducer;
