import React from "react";
import { Navbar } from "components/templates";

const Layout = (props) => {
  return (
    <div className="main-wrapper">
      { props.navbar === true && <Navbar /> }
      {props.children}
    </div>
  );
};

export default Layout;
