/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useEffect, useState, useRef } from "react";
import { Layout, Navbar, MenuSidebar } from "components/templates";
import { Body, Flex, FormGroup, Container } from "components/atoms";
import { Profile, Modal } from "components/organisms";
import { ClassItem } from "components/molecules";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isLogin, userData } from "utils/helpers.js";
import parse from "html-react-parser";
import { conferenceDetail } from "utils/api";
import bacod from "assets/images/bacod-sm.png";

const ClassDetail = () => {
  const { id } = useParams();
  const [loadingGetClass, setLoadingGetClass] = useState(false);
  const [detailClass, setDetailClass] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getDetailClass();
  }, []);

  const getDetailClass = () => {
    setLoadingGetClass(true);
    conferenceDetail(id)
      .then((response) => {
        if (response.data.success) {
          setDetailClass(response.data.results);
        }
      })
      .catch((error) => {
        console.log("ERROR =====>", error);
      })
      .finally(() => {
        setLoadingGetClass(false);
      });
  };

  return (
    <Layout>
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            { isLogin()
              ? <Link to="/">
                <img src={bacod} alt="BACOD Logo" className="login-logo" />
              </Link>
              : <Link to="/what" className="color-white hidden-anchor">WHAT IS BACOD?</Link>
            }
          </div>
          <div className="navbar-program">
            {
              isLogin()
                ? <Profile avatar={userData("avatar")} name={userData("name")} id={userData("id")} />
                : <button type="button" onClick={() => { navigate("/login"); }} className="btn btn-primary btn-rounded bold px-6">LOGIN</button>
            }
          </div>
        </div>
      </Navbar>
      <Body navbar className="pb-15">
        {
          detailClass &&
          <Container>
            <div className="mb-15">
              <h1
                className="mt-8 page-title cursor-pointer"
                onClick={() => { navigate("/class-list/1"); }}
              >
                <i className="feather icon-arrow-left"></i> { detailClass.title }
              </h1>

            </div>
            <div>
              {
                detailClass.content
                  ? parse(detailClass.content)
                  : <h1 className="text-center color-red-1 italic"><i> NO CONTENT </i></h1>
              }
            </div>
            {/* <div className="content-section">
              <div className="content-section-header">
                <div className="content-section-title">TESTING</div>
              </div>
              <div className="content-section-body">
                <div className="display-flex align-x-center align-y-center">
                  <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/jGQ6l9eIJ6w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
            </div> */}
          </Container>
        }
      </Body>
    </Layout>
  );
};

export default ClassDetail;
