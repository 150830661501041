/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React from "react";
import { Layout } from "components/templates";
import { Body } from "components/atoms";

const Code401 = () => {
  return (
    <Layout>
      <Body>
        <h1 className="text-center">404</h1>
      </Body>
    </Layout>
  );
};

export default Code401;
