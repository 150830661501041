import React, { useEffect } from "react";
// import { setLogin, setToken, setUserData } from "store/actions/userAction.js";
import Routes from "./routes.js";
import { Provider } from "react-redux";
import store from "store";
import { userCheck } from "utils/api.js";
import { userData, logout } from "utils/helpers.js";
// #region - IMPORT STYLE
import "./assets/feather-icon/feather-icon.css";
import "./assets/scss/main.scss";
// #endregion - IMPORT STYLE

// // #region - USER CONFIGURE
// if (localStorage.getItem("token") || localStorage.getItem("userData")) {
//   const dispatch = useDispatch();
//   const userData = JSON.parse(localStorage.getItem("userData"));
//   const userToken = localStorage.getItem("token");

//   dispatch(setLogin(true));
//   dispatch(setToken(userToken));
//   dispatch(setUserData(userData));
// } else {
//   localStorage.removeItem("token");
//   localStorage.removeItem("userData");
// }
// // #endregion - USER CONFIGURE

const App = () => {
  useEffect(() => {
    checkBanned();
  }, []);

  const checkBanned = async () => {
    if (!["/banned", "/login", "/register", "/teams"].includes(window.location.pathname) && userData("id")) {
      try {
        const response = await userCheck(userData("id"));
        const user = response.data.results;
        if (!user.isActive) {
          logout(() => {
            window.location.href = "/banned";
          });
        }
      } catch (error) {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert("SOMETHING WRONG, PLEASE REFRESH THE PAGE");
        }
      }
    }
  };

  return (
    <Provider store={store}>
      <div className="mobile-container">
        <Routes />
      </div>
    </Provider>
  );
};

export default App;
