/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import { Link } from "react-router-dom";

const MenuSidebarItem = (props) => {
  return (
    <li className="menu-sidebar-item">
      <Link to={props.link ? props.link : "#"} className="menu-sidebar-item-wrapper">
        <div className="menu-sidebar-item-icon">
          {props.children}
        </div>
        <div className="menu-sidebar-item-title">
          {props.title}
        </div>
      </Link>
    </li>
  );
};

export default MenuSidebarItem;
