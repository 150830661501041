const attendanceState = {
  unlock: false,
  isSubmitAttendance: false,
  dataConference: {},
};

const attendanceReducer = (state = attendanceState, action) => {
  const { type, payload } = action;
  switch (type) {
  case "SET_ATTENDANCE":
    return {
      ...state,
      unlock: payload,
    };
  case "SET_SUBMIT_ATTENDANCE":
    return {
      ...state,
      isSubmitAttendance: payload,
    };
  case "SET_DATA_CONFERENCE":
    return {
      ...state,
      dataConference: payload,
    };
  default:
    return {
      ...state,
    };
  }
};

export default attendanceReducer;
