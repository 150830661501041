/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";
import { ClassMentorItem } from "components/atoms";
import { Link, useNavigate } from "react-router-dom";

const ClassItem = (props) => {
  const navigate = useNavigate();
  const className = [props.className];
  if (props.dark) {
    className.push("form-group-dark");
  }

  const to = (link) => {
    navigate(link);
  };

  return (
    <li className="class-item">
      <div className="class-item-detail" onClick={() => { to(props.link); }}>
        <div className="class-item-icon">
          <div className="class-item-circle">
            { props.children }
          </div>
        </div>
        <div className="class-item-description">
          <Link className="class-item-title" to={props.link}>{ props.title }</Link>
          {
            (props.mentors && props.mentors.length > 0)
              ? <div className="class-item-mentor">
                {
                  props.mentors.map((item, index) => {
                    return (
                      <ClassMentorItem
                        name={item.name}
                        avatar={item.avatar}
                        key={index}
                      />
                    );
                  })
                }
              </div>
              : <i> Mentor has not been assigned </i>
          }
        </div>
        {/* <div className="class-item-date">
          <div className="class-item-calendar">
            <div className="class-item-calendar-day"></div>
            <div className="class-item-calendar-month"></div>
            <div className="class-item-calendar-year"></div>
          </div>
        </div> */}
      </div>
    </li>
  );
};

export default ClassItem;
