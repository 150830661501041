/*
=============================
THIS CODE ORIGINAL BY RESITDC
=============================
*/

import React from "react";

const DisplayFlex = (props) => {
  const className = [props.className];
  if (props.inline) {
    className.push("display-inline-flex");
  } else {
    className.push("display-flex");
  }
  return (
    <div className={className.join(" ")}>
      {props.children}
    </div>
  );
};

export default DisplayFlex;
