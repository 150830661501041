
import React, { useState } from "react";
import { Layout, Navbar } from "components/templates";
import { Body, Flex, Container, FormGroup, LogoFacebook, LogoGoogle, LogoGithub, Box } from "components/atoms";
import { storeData, logout, userData } from "utils/helpers.js";
import defaultAvatar from "assets/images/avatar.png";
import parse from "html-react-parser";

const Certificates = () => {
  return (
    <>
      <h1 className="color-white">Settings</h1>

      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Name</h2>
        <p className="nm">{userData("name")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Username</h2>
        <p className="nm">{userData("username")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Phone</h2>
        <p className="nm">{userData("phone")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Email</h2>
        <p className="nm">{userData("email")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Birth Date</h2>
        <p className="nm">{userData("birthDate")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Gender</h2>
        <p className="nm">{userData("gender")}</p>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
      <Box fullWidth radius="sm" shadow className="bg-black-4 mt-5 overflow-hidden border-1 bc-black-7 profile-box-padding">
        <h2 className="mb-2">Password</h2>
        <div className="profile-setting-edit">
          <button type="button" className="btn-profile-setting-edit">
            <i className="feather icon-edit-2"></i>
          </button>
        </div>
      </Box>
    </>
  );
};

export default Certificates;
