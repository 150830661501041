import React, { useState, useEffect } from "react";
import { Body, Container, Flex, FormGroup, SkeletonLoading } from "components/atoms";
import { Layout, Navbar, MenuSidebar } from "components/templates";
import axios from "services/axios";
import defaultAvatar from "assets/images/avatar.png";
import "./index.scss";

const Teams = () => {
  const [limit, setLimit] = useState(200);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [members, setMembers] = useState([]);

  useEffect(() => {
    loadFirst();
  }, []);

  const getData = (myPage, searchKeywords) => {
    return axios.get("/members", {
      params: {
        limit: limit,
        page: myPage,
        search: searchKeywords,
      },
    });
  };

  const loadFirst = (searchKeywords) => {
    setLoading(true);
    getData(1, searchKeywords)
      .then((response) => {
        if (response.data.success) {
          const results = response.data.results;
          setMembers(results.data);
          setTotalData(results.pagination.totalData);
        }
      })
      .catch((error) => {
        if (error) {
          setMembers([]);
          setTotalData(0);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchMember = () => {
    loadFirst(searchInput);
  };

  return (
    <Layout>
      <Body>
        <Flex className="align-x-center align-y-center bacod-responsive-pt">
          <div className="bacod-logo-wrapper">
            <div className="bacod-logo"></div>
          </div>
        </Flex>
        <Container>
          <p className="text-center px-9 fs-md">Member BACOD yang muncul disini hanya member yang sudah verifikasi email</p>
          <div className="bacod-teams">
            <Flex className="bacod-teams-filters">
              <div className="bacod-teams-search">
                <form onSubmit={searchMember}>
                  <input
                    type="search"
                    onChange={(e) => {
                      loadFirst(e.target.value);
                    }}
                    className="form-input form-input-rounded bg-black-3 px-7 color-white"
                    placeholder="Search"
                  />
                </form>
              </div>
              <div className="bacod-teams-sort">
                <button type="button" className="btn-bacod-teams-sort">
                  <i className="feather icon-grid"></i>
                </button>
                <button type="button" className="btn-bacod-teams-sort">
                  <i className="feather icon-list"></i>
                </button>
              </div>
            </Flex>
            <div className="row mt-10">
              {
                loading
                  ? [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                    return (
                      <div className="col-3 col-tab-3 col-mobile-6 py-6 mb-9" key={index}>
                        <div className="team">
                          <SkeletonLoading width="108px" height="108px" radius="100%" className="mb-3" />
                          <div className="team-name">
                            <SkeletonLoading width="100%" height="20px" />
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : members.map((item, index) => {
                    return (
                      <div className="col-3 col-tab-3 col-mobile-6 py-6 mb-9" key={index}>
                        <div className="team">
                          <div className="team-avatar">
                            <img
                              src={item.avatar ? item.avatar : defaultAvatar}
                              alt="Restu Dwi Cahyo"
                              className="team-avatar-image"
                            />
                          </div>
                          <div className="team-name"> { item.name } </div>
                          <div className="team-position"> { item.position } </div>
                        </div>
                      </div>
                    );
                  })
              }
            </div>
            {
              totalData < 1 && !loading &&
              <h1 className="text-center py-10">DATA NOT FOUND</h1>
            }
          </div>
        </Container>
      </Body>
    </Layout>
  );
};

export default Teams;
