import React, { useEffect, useState } from "react";
import { FormGroup } from "components/atoms";
import { useSelector, useDispatch } from "react-redux";
import { setSubmitAttendance } from "store/actions/attendanceAction.js";
import { userData } from "utils/helpers.js";
import { attendanceCreate } from "utils/api";

const AttendanceInput = (props) => {
  const [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
  const [noteAttendance, setNoteAttendance] = useState("");
  const [attendanceSubmitErrorMessage, setAttendanceSubmitErrorMessage] = useState("");
  const dataConference = useSelector(state => state.attendanceReducer.dataConference);
  const dispatch = useDispatch();

  const closeAttendance = () => {
    if (props.closeAttendance) props.closeAttendance();
  };

  const submitAttendance = (e) => {
    e.preventDefault();
    setIsLoadingAttendance(true);
    const dateNow = new Date().toISOString("en-ID", {
      timeZone: "Asia/Jakarta",
    });

    const formData = {
      bootcampConferenceId: dataConference.id,
      userId: userData("id"),
      note: noteAttendance,
      checkin: dateNow,
    };

    attendanceCreate(formData)
      .then((response) => {
        if (response.data.success) {
          dispatch(setSubmitAttendance(true));
        } else {
          setAttendanceSubmitErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          if (error.response.data) {
            setAttendanceSubmitErrorMessage(error.response.data.message);
          } else {
            setAttendanceSubmitErrorMessage("SOMETHING WRONG");
          }
        } else {
          setAttendanceSubmitErrorMessage("SOMETHING WRONG");
        }
      })
      .finally(() => {
        setIsLoadingAttendance(false);
      });
  };

  return (
    <>
      <form onSubmit={submitAttendance}>
        <div
          className="display-flex align-x-center align-y-center mb-2"
          style={{ fontSize: "5rem" }}
        >
          <i className="feather icon-check-square color-white"></i>
        </div>
        <h3 className="text-center color-white">KLIK TOMBOL <i>HADIR</i> UNTUK MENGISI ABSENSI</h3>
        <FormGroup message={attendanceSubmitErrorMessage} className="mt-6">
          <textarea
            className="form-input"
            placeholder="CATATAN"
            onChange={(e) => { setNoteAttendance(e.target.value); }}
            disabled={isLoadingAttendance}
          ></textarea>
        </FormGroup>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary bold btn-rounded px-8 py-2 mt-10 mr-5"
          >
            { isLoadingAttendance ? "LOADING..." : "HADIR" }
          </button>
        </div>
      </form>
    </>
  );
};

export default AttendanceInput;
